import React, { useEffect } from 'react';
import { Card, CardGroup, Col, Container, Row } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';

const IphoneCd: React.FC = () => {

    const weddingDate = new Date("Jul 8, 2023 17:00:00").getTime();
    const now = new Date().getTime();
    const distance = weddingDate - now;
    
    useEffect(() => {
        const intervalId = setInterval(tick, 1000);
        
        // Nettoyer l'intervalle lors du démontage du composant
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    function tick() {
        const now = new Date().getTime();
        var timeLeft = (weddingDate - now) / 1000;
        updateClock(timeLeft);
    }

    function updateClock(remainingTime: any) {
        const days = Math.floor(remainingTime / 86400);
        const daysElement = document.getElementById("days");
        if (daysElement) {
            daysElement.innerHTML = days + " ";
        }
        remainingTime -= days * 86400;

        const hours = Math.floor(remainingTime / 3600) % 24;
        const hoursElement = document.getElementById("hours");
        if (hoursElement) {
            hoursElement.innerHTML = hours + " ";
        }
        remainingTime -= hours * 3600;

        const minutes = Math.floor(remainingTime / 60) % 60;
        const minutesElement = document.getElementById("minutes");
        if (minutesElement) {
            minutesElement.innerHTML = minutes + " ";
        }
        remainingTime -= minutes * 60;

        const seconds = Math.floor(remainingTime % 60);
        const secondsElement = document.getElementById("seconds");
        if (secondsElement) {
            secondsElement.innerHTML = seconds + " ";
        }
    }

  return (
    <>
        <br/>
        <br/>
        
        {distance > 0 && (
            <>
                <Container >
                    <Row>
                        <Col className="countdown_card card border-0">
                            <Card.Body>
                            <div className="countdown_section">
                                <div id="days" className="iphonecd_value"></div>
                                <div className="countdown_label text-kease">Jours</div>
                            </div>
                            </Card.Body>
                        </Col>
                        <Col className="countdown_card card border-0">
                            <Card.Body>
                                <div className="countdown_section">
                                    <div id="hours" className="iphonecd_value"></div>
                                    <div className="countdown_label text-kease">Heures</div>
                                </div>
                            </Card.Body>
                        </Col>
                        <Col className="countdown_card card border-0">
                            <Card.Body>
                                <div className="countdown_section">
                                    <div id="minutes" className="iphonecd_value"></div>
                                    <div className="countdown_label text-kease">Minutes</div>
                                </div>
                            </Card.Body>
                        </Col>
                        <Col className="countdown_card card border-0">
                            <Card.Body>
                                <div className="countdown_section">
                                    <div id="seconds" className="iphonecd_value"></div>
                                    <div className="countdown_label text-kease">Secondes</div>
                                </div>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </>
        )}
        {distance < 0 &&(
            <>
                <CardGroup className="card-deck">
                    <Card className="rounded-top-kease card border-0">
                        <div className="card-body">
                            <h5>La date est révolue - Place aux photos</h5>
                        </div>
                    </Card>
                </CardGroup>
            </>
        )}     
        
    </>
  );
};

export default IphoneCd