import { Container, Nav, Navbar, Button } from 'react-bootstrap';
import '../../index.css';
import {ReactComponent as ReactLogo} from '../../assets/logo_mariage.svg';
import {ReactComponent as ReactDeparture} from '../../assets/plane-departure-solid.svg';
import {ReactComponent as ReactMap} from '../../assets/map-marked-alt-solid.svg';
import {ReactComponent as ReactDirection} from '../../assets/map-signs-solid.svg';
import {ReactComponent as ReactAround} from '../../assets/street-view-solid.svg';
import {ReactComponent as ReactPhoto} from '../../assets/photo.svg';
import {ReactComponent as ReactContact} from '../../assets/call.svg';
import { useLinkNavigation } from './MenuFonctions';

const PublicMenu: React.FC=  () => {
    
    const { expanded, toggleExpanded, createLink } = useLinkNavigation();
    const handleClick = (useLink:any) => {
        createLink(useLink); // Utilisation de la fonction createLink
      };

    return (
        <>
        <Navbar expanded={expanded} onToggle={toggleExpanded} className="color-mariage" variant="dark" fixed="top" expand="xl">
            <Container fluid>
                <Navbar.Brand className="app-header-logo"  onClick={() => handleClick("")}>
                    <ReactLogo className="bi bi-cloud-check" width="50" height="50"/>{' '}
                </Navbar.Brand>
                <Navbar.Toggle/>
                <Navbar.Collapse>
                    <Nav className="me-auto">
                        <Nav.Link onClick={() => handleClick("programme")} className="m-3" >
                            Le Programme{' '}
                            <ReactDirection className="bi bi-cloud-check align-self-center" width="25" height="25"/>
                        </Nav.Link>
                        <Nav.Link onClick={() => handleClick("navettes")} className="m-3">  
                            Transport{' '}
                            <ReactAround className="bi bi-cloud-check align-self-center" width="25" height="25"/>
                        </Nav.Link>
                        <Nav.Link onClick={() => handleClick("hebergement")} className="m-3">  
                            Hébergement{' '}
                            <ReactMap className="bi bi-cloud-check align-self-center" width="25" height="25"/>
                        </Nav.Link>
                        <Nav.Link onClick={() => handleClick("lune-de-miel")} className="m-3" >
                            Lune de miel{' '}
                            <ReactDeparture className="bi bi-cloud-check align-self-center" width="25" height="25"/>
                        </Nav.Link>
                        <Nav.Link onClick={() => handleClick("photos")} className="m-3" >
                            Photos{' '}
                            <ReactPhoto className="bi bi-cloud-check align-self-center" width="25" height="25"/>
                        </Nav.Link>
                        <Nav.Link onClick={() => handleClick("contacts")} className="m-3" >
                            Contacts{' '}
                            <ReactContact className="bi bi-cloud-check align-self-center" width="25" height="25"/>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>

                <Navbar.Collapse className="mx-auto justify-content-end">
                    <Nav>
                        <Button onClick={() => handleClick("login")} className="border-0 btn btn-kease m-3" >
                            Se&nbsp;connecter
                        </Button>
                        <br className="d-none d-xs-none d-md-none"/>
                        <Button onClick={() => handleClick("create-account")} className="border-0 btn btn-kease m-3">
                            S'inscrire
                        </Button>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        </>
    );

};

export default PublicMenu


