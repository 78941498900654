import { Card, Col, Form, Row, Image } from 'react-bootstrap';
import { ref, getDownloadURL  } from 'firebase/storage';
import React, { useEffect, useState } from 'react';
import { dbPhotos } from '../../utils/firebaseconfig';
import ProgressCircle from '../utils/CircularProgressBar';

const Programme: React.FC = () => {
    const [Accueil, setAccueil] = useState<string>('');
    const [Cocktail, setCocktail] = useState<string>('');
    const [Ceremonie, setCeremonie] = useState<string>('');
    const [Reception, setReception] = useState<string>('');
    const [Brunch, setBrunch] = useState<string>('');

      const [photos, setPhotos] = useState<string[]>([
        'programme/Accueil.jpg',
        'programme/Cocktail.jpg',
        'programme/Ceremonie.jpg',
        'programme/Reception.jpg',
        'programme/Brunch.jpg',

      ]);
      
      useEffect(() => {
        photos.forEach((photo, index) => {
          const storageRef = ref(dbPhotos, photo);
          // Récupération de l'URL de téléchargement de l'image
          getDownloadURL(storageRef)
            .then((url) => {
              switch (index) {
                case 0:
                  setAccueil(url);
                  break;
                case 1:
                  setCocktail(url);
                  break;
                case 2:
                  setCeremonie(url);
                  break;
                case 3:
                  setReception(url);
                  break;
                case 4:
                  setBrunch(url);
                  break;
                default:
                  break;
              }
            })
            .catch((error) => {
              console.error(error);
            });
        });
      }, []);

  return (
    <>
        <Card className="border-0 mx-auto" >
          <Card.Body>
            <h2 className="text-center mb-4 text-kease">Le Programme</h2>
          </Card.Body>
        </Card>
          
        <Card className="border-0 mx-auto">
          <Row className="mx-auto">
            <Col className="align-self-center text-center">
              <Image className="align-self-center text-center" src={Accueil} width="300" height="300" roundedCircle/>
            </Col>
            <Col className="align-self-center text-center">
              <h3 className="text-white kease-font border-0 rounded">A partir de<br/>16h30</h3>
            </Col>
            <Col className="align-self-center text-center">
                <h2 className="text-center text-kease">Accueil</h2>
                <p className="text-center">Rejoignez-nous au <strong>Pressoir de Tourgeville</strong> pour un rafraîchissement avant le lancement.</p>
                <a className="text-link-kease text-center" href="https://www.waze.com/fr/live-map/directions/fr/normandie/tourgeville/le-pressoir-de-tourgeville?navigate=yes&to=place.ChIJzU1hwg7U4UcREUdpH0I6Mhg">(Ferme de barreaux, 14800 Tourgéville)</a>
            </Col>
          </Row>
        </Card>
        <br/>
        <br/>
        <Card className="border-0 mx-auto">
          <Row className="mx-auto">
          <Col className="align-self-center text-center">
              <h2 className="text-center text-kease">Cérémonie laïque</h2>
              <p className="text-center ">Nous tenons à remercier vivement <strong>Camille</strong> et <strong>Lucas</strong>, qui nous accompagneront dans l'organisation de cette Cérémonie.</p>
          </Col>
          <Col className="align-self-center text-center">
              <h3 className="text-white kease-font border-0 rounded">A partir de<br/>17h00</h3>
            </Col>
          <Col className="align-self-center text-center">
            <Image className="align-self-center" src={Ceremonie} width="300" height="300" roundedCircle/>
          </Col>
          </Row>
        </Card>
        <br/>
        <br/>
        <Card className="border-0 mx-auto">
          <Row className="mx-auto">
            <Col className="align-self-center text-center">
              <Image className="align-self-center" src={Cocktail} width="300" height="300"  roundedCircle/>
            </Col>
            <Col className="align-self-center text-center">
                <h3 className="text-white kease-font border-0 rounded">A partir de<br/>18h00</h3>
              </Col>
            <Col className="align-self-center text-center">
                <h2 className="text-center mb-4 text-kease">Cocktail</h2>
                <p className="text-center mb-4">Le vin d'honneur se tiendra sur la terrasse du Préssoir<br/></p>
            </Col>
          </Row>
        </Card>
        <br/>
        <br/>
        <Card className="border-0 mx-auto">
          <Row className="mx-auto">
          <Col className="align-self-center text-center">
              <h2 className="text-center mb-4 text-kease">Réception</h2>
              <p className="text-center mb-4">Un petit plan de table sera présent à l'entrée de la salle.<br/></p>
          </Col>
          <Col className="align-self-center text-center">
                <h3 className="text-white kease-font border-0 rounded">A partir de<br/>20h00</h3>
              </Col>
          <Col className="align-self-center text-center">
            <Image className="align-self-center" src={Reception} width="300" height="300" roundedCircle/>
          </Col>
          </Row>
        </Card>
        <br/>
        <br/>
        <Card className="border-0 mx-auto">
          <Row className="mx-auto">
          <Col className="align-self-center text-center">
            <Image className="align-self-center" src={Brunch} width="300" height="300" roundedCircle/>
          </Col>
          <Col className="align-self-center text-center">
                <h3 className="text-white kease-font border-0 rounded">A partir de<br/>11h00 le lendemain</h3>
              </Col>
          <Col className="align-self-center text-center" >
              <h2 className="text-center mb-4 text-kease">Brunch</h2>
              <p className="text-center mb-4">Afin de finaliser les festivités, nous avons prévu un brunch le lendemain.<br/></p>
          </Col>
          </Row>
        </Card>
      <br/>
      <br/>
    </>
  );
};

export default Programme