// CircularProgressBar.tsx

import React, { useEffect, useState } from 'react';

interface AuthProps {
  progress: any;
}

const CircularProgressBar: React.FC<AuthProps> = ({ progress }) => {

  const [circleRadius, setCircleRadius] = useState<number>(0);
  const angle = (progress / 100) * 360;

  useEffect(() => {
    const fetchData = async () => {
      const radius = (progress / 100) * 100;
      setCircleRadius(radius);
    };

    fetchData();
  }, [progress]);


  const progressBarStyle: React.CSSProperties = {
    width: '200px',
    height: '200px',
    borderRadius: '50%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const progressCircleStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    background: `conic-gradient(#c04c36 ${progress}%, #fae1db ${progress}% 100%)`,
    position: 'absolute',
    clipPath: 'polygon(50% 0, 100% 0, 100% 100%, 0 100%, 0 0)',
  };

  const progressCenterStyle: React.CSSProperties = {
    width: `80%`,
    height: `80%`,
    borderRadius: '50%',
    background: '#fff',
    position: 'absolute',
    clipPath: 'polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%, 50% 0%)',
  };

  const zerocircleStyle: React.CSSProperties = {
    width: '10%',
    height: '10%',
    borderRadius: '50%',
    background: '#c04c36',
    position: 'absolute',
    top: '5%',
    left: '50%',
    transform: `translate(-50%, -50%) rotateZ(0deg)`,
    transformOrigin: `50% 100px`,
  };

  const gradientcircleStyle: React.CSSProperties = {
    width: '10%',
    height: '10%',
    borderRadius: '50%',
    background: '#c04c36',
    position: 'absolute',
    top: '5%',
    left: '50%',
    transform: `translate(-50%, -50%) rotateZ(${angle}deg)`,
    transformOrigin: `50% 100px`,
  };

  const textStyle: React.CSSProperties = {
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#c04c36',
  };

  const zeroCircle = progress > 0 && <div style={zerocircleStyle} />;
  const gradientCircle = progress > 0 && <div style={gradientcircleStyle} />;

  return (
    <>
    <div style={progressBarStyle}>
      <div style={progressCircleStyle} />
      {zeroCircle}
      {gradientCircle}
      <div className="d-flex align-items-center justify-content-center" style={progressCenterStyle}>
        <div className=" " style={textStyle}>{progress}%</div>
      </div>
    </div>
    </>
  );
};

export default CircularProgressBar;
