import { Route, Routes, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import MenuItems from '../components/appheader/MenuItems';

import Onepage from '../components/onepage/OnePage';
import CreateAccount from '../components/auth/CreateAccount';
import Programme from '../components/onepage/Programme';
import Navettes from '../components/onepage/Navettes';
import Hebergement from '../components/onepage/Hebergement';
import Contact from '../components/onepage/Contact';
import Photos from '../components/onepage/Photos';
import Cagnotte from '../components/onepage/Lunedemiel';
import Contacts from '../components/onepage/Contacts';

import Login from '../components/auth/Login';
import Userwaitvalidation from '../components/auth/UserValidation';
import Profil from '../components/auth/UserProfile';
import Download from '../components/auth/Download';
import Upload from '../components/auth/Upload';
import Admin from '../components/auth/admin/Admin';

import RequiredAuth from './RequiredAuth';
import ValidatedAuth from './ValidatedAuth';
import AdminAuth from './AdminAuth';
import UserContext from '../components/utils/UserContext';
import Tables from '../components/auth/admin/Table';
import Logements from '../components/auth/admin/Logement';

const AppRouter : React.FC = ()=> {
 
  // Vérifiez si l'utilisateur est déjà connecté lors du chargement de l'application
  const { user, userProfile } = useContext(UserContext);

  return (
    <>
      
        <br/>
        <br/>
        <br/>
        <br/>
      <Routes>

          <Route path={'/'} element={<Onepage />}/>

          <Route path={'/create-account'} element={<CreateAccount />}/>
          <Route path={'/programme'} element={<Programme />}/>
          <Route path={'/navettes'} element={<Navettes />}/>
          <Route path={'/hebergement'} element={<Hebergement />}/>
          <Route path={'/contact-us'} element={<Contact />}/>
          <Route path={'/photos'} element={<Photos />}/>
          <Route path={'/lune-de-miel'} element={<Cagnotte />}/>
          <Route path={'/contacts'} element={<Contacts />}/>

          <Route path={'/login'} element={<Login />}/>

          <Route path={'/wait-validation'} element={<RequiredAuth><Userwaitvalidation /></RequiredAuth>}/>
          <Route path={'/user-profile'} element={<RequiredAuth><Profil /></RequiredAuth>}/>

          <Route path={'/download'} element={<ValidatedAuth userProfile={userProfile}><Download /></ValidatedAuth>}/>
          <Route path={'/upload'} element={<ValidatedAuth userProfile={userProfile}><Upload /></ValidatedAuth>}/>

          <Route path={'/admin'} element={<AdminAuth userProfile={userProfile}><Admin/></AdminAuth>}/>
          <Route path={'/tables'} element={<AdminAuth userProfile={userProfile}><Tables/></AdminAuth>}/>
          <Route path={'/logements'} element={<AdminAuth userProfile={userProfile}><Logements/></AdminAuth>}/>

          <Route path="*" element={<Onepage />}/>
    
      </Routes>
  </>
  );
};

export default AppRouter