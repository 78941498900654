import React, { useRef, useState } from "react"
import {
    createUserWithEmailAndPassword,
  } from "firebase/auth";
import { Form, Button, Card, Alert, Col } from "react-bootstrap"
import { auth, dbCollection } from "../../utils/firebaseconfig";
import { Link, useNavigate } from "react-router-dom"
import { set, ref } from "firebase/database";

const CreateAccount: React.FC = () => {

    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const confirmpasswordRef = useRef<HTMLInputElement>(null);
    const firstnameRef = useRef<HTMLInputElement>(null);
    const lastnameRef = useRef<HTMLInputElement>(null);
    const phoneRef = useRef<HTMLInputElement>(null);
    const [error, setError] = useState("")
    const navigate = useNavigate()

    async function handleSubmit(event:any) {
        event.preventDefault()
    
        if (passwordRef.current!.value !== confirmpasswordRef.current!.value) {
          return setError("Les mots de passe ne sont pas identiques")
        }
        try {
            setError("")
            const userfirebase = await createUserWithEmailAndPassword(
                auth,
                emailRef.current!.value,
                passwordRef.current!.value
            );
            const user = userfirebase.user;
            await set(ref(dbCollection, 'users/' + user.uid), {
                email: user.email,
                firstname : firstnameRef.current!.value,
                lastname : lastnameRef.current!.value,
                phone : phoneRef.current!.value,
                statut : 'en attente validation',
            });
            navigate('/wait-validation')
        } catch {
          setError("Impossible de créer votre compte - veuillez réessayer ultérieurement ou contacter un administrateur")
        }
      }

    return (
    <>
        <Card className="border-0 mx-auto">
            <Card.Body className="mx-auto">
                <h2 className="text-center mb-3 text-kease">Créer un compte</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form className="mx-auto" onSubmit={handleSubmit}>
                    <Form.Group className="mb-3 row mx-auto" id="email">
                        <Col>
                            <Form.Control className="mx-auto" type="email" ref={emailRef} placeholder="email" required />
                        </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row mx-auto" id="password">
                        <Col xs={6}>    
                            <Form.Control className="mx-auto" type="password" ref={passwordRef} placeholder="Mot de passe" required />
                        </Col>
                        <Col xs={6}>
                            <Form.Control className="mx-auto" type="password" ref={confirmpasswordRef} placeholder="Confirmer mot de passe" required />
                        </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row  mx-auto" id="name">
                        <Col xs={6}>    
                            <Form.Control className="mx-auto" type="name" ref={firstnameRef} placeholder="Prénom" required />
                        </Col>
                        <Col xs={6}>
                            <Form.Control className="mx-auto" type="name" ref={lastnameRef} placeholder="Nom" required />
                        </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row mx-auto" id="contact">
                        <Col>
                            <Form.Control className="mx-auto" type="phone" ref={phoneRef} placeholder="Téléphone" required />
                        </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row mx-auto">
                        <Col>
                            <Button className="btn-kease text-center w-100" type="submit">
                                Créer son compte
                            </Button>
                        </Col>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
        <div className="mx-auto text-center mb-2">
            Vous avez déjà un compte? <Link to="/login">Se connecter</Link>
        </div>
        <br/>
    </>
    )
}

export default CreateAccount