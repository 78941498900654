import React from 'react';
import { Container, Navbar } from 'react-bootstrap';

export const AppFooter = () => {
  return (
    <>
      <Navbar className="color-mariage"  variant="dark">
        <Container fluid>
          <div className="w-100 text-center text-white py-4">
            Tous droits réservés &#169; {new Date().getFullYear()} aux mariés
          </div>
        </Container>
      </Navbar>
    </>
  );
};
