import {  Container } from 'react-bootstrap';
import Mariage from '../../assets/Mariage-white.svg';
import Countdown from './Countdown';
import React, { useState, useEffect } from 'react';
import IphoneCd from './Iphone';

const Photo: React.FC = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  return (
    <>
    <Container fluid>
      {!isSmallScreen && <div className="homepage" >
        <Countdown></Countdown>
            <br/>
            
            <br/>
            <br/>
            
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className="">
                <img src={Mariage}/>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div> }

    {isSmallScreen && <div >
      <div className="">
            <img src={Mariage}/>
        </div>
        
        <br/>
        <IphoneCd></IphoneCd>
        <br/>
    </div> }
    
    </Container>
    </>
  );
};

export default Photo