import { CardGroup, Card, Col, Form, Row, Image } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import { dbPhotos } from '../../utils/firebaseconfig';
import GoogleMaps from '../utils/GoogleMaps';


const Hebergement: React.FC = () => {
  const [Normandy, setNormandy] = useState<string>('');
  const [Royal, setRoyal] = useState<string>('');
  const [Ibis, setIbis] = useState<string>('');
  const [Mercure, setMercure] = useState<string>('');
  const [Novotel, setNovotel] = useState<string>('');
  const [GitedeFrance, setGitedeFrance] = useState<string>('');
  const [Airbnb, setAirbnb] = useState<string>('');

    const [photos, setPhotos] = useState<string[]>([
      'hebergement/Normandy.jpeg',
      'hebergement/Royal.jpeg',
      'hebergement/Ibis-style.jpeg',
      'hebergement/Mercure-deauville.jpeg',
      'hebergement/Novotel-plage.jpeg',
      'hebergement/gites-de-france.jpg',
      'hebergement/airbnb.png',
    ]);

    const apiKey = process.env.REACT_APP_APIKEY;

    useEffect(() => {
      photos.forEach((photo, index) => {
        const storageRef = ref(dbPhotos, photo);
        // Récupération de l'URL de téléchargement de l'image
        getDownloadURL(storageRef)
          .then((url) => {
            switch (index) {
              case 0:
                setNormandy(url);
                break;
              case 1:
                setRoyal(url);
                break;
              case 2:
                setIbis(url);
                break;
              case 3:
                setMercure(url);
                break;
              case 4:
                setNovotel(url);
                break;
              case 5:
                setGitedeFrance(url);
                break;
              case 6:
                setAirbnb(url);
                break;
              default:
                break;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      });
    }, []);

  return (
    <>
        <Card className="border-0 mx-auto" >
          <Card.Body>
            <h2 className="text-center mb-4 text-kease">Les Hébergements</h2>
          </Card.Body>
        </Card>

        
        <Card className="border-0 mx-auto">
          <Row className="mx-auto">
          
          <Col md={6}>
            <Card.Body>
              <h2 className="text-center mb-4 text-kease">Les petits lieux que nous vous recommandons</h2>
              <p>Les hôtels sont tous situés proche du point de dépose pour plus de facilité.
                Les navettes ont environ 15 min de trajet du lieu du mariage au point de dépose, la mairie de Deauville.
              Il vous restera quelques mètres à faire à pied.</p>
              <br/>
              <p>Le lendemain, on vous prend en charge au même endroit, à la Mairie de Deauville.</p>
              <p >Plusieurs Vans seront disponibles pour vous amener au brunch.</p>
              <br/>
            </Card.Body>
          </Col>
          <Col md={6}>
            <GoogleMaps apiKey={apiKey} />
          </Col>
          </Row>
        </Card>

        <br/>

        <Card className="border-0 mx-auto bg-kease" >
            <Card.Body className="text-center">
                <h2 className="text-center text-white">Les Hôtels</h2>
            </Card.Body>
        </Card>
        <br/>
        <CardGroup className="card-deck">
            <Card className="border-0 mx-auto" >
                <Image src={Ibis} className="bi bi-cloud-check align-self-center " width="200" height="200" roundedCircle/>
                <Card.Body className="text-center">
                    <h2 className="text-center text-kease">Ibis</h2>
                </Card.Body>
            </Card>
            <Card className="border-0 mx-auto" >
                <Image src={Novotel} className="bi bi-cloud-check align-self-center " width="200" height="200" roundedCircle/>
                <Card.Body className="text-center">
                    <h2 className="text-center text-kease">Novotel</h2>
                </Card.Body>
            </Card>
            <Card className="border-0 mx-auto" >
                <Image src={Mercure} className="bi bi-cloud-check align-self-center " width="200" height="200" roundedCircle/>
                <Card.Body className="text-center">
                    <h2 className="text-center text-kease">Mercure</h2>
                </Card.Body>
            </Card>
        </CardGroup>
        <br/>

        <Card className="border-0 mx-auto bg-kease" >
            <Card.Body className="text-center">
                <h2 className="text-center text-white">Les Chambres d'hôtes</h2>
            </Card.Body>
        </Card>
        <br/>
        <CardGroup className="card-deck">
            <Card className="border-0 mx-auto" >
                <Image src={Airbnb} className="bi bi-cloud-check align-self-center " width="200" height="200" roundedCircle/>
                <Card.Body className="text-center">
                    <h2 className="text-center text-kease">Airbnb</h2>
                </Card.Body>
            </Card>
            <Card className="border-0 mx-auto" >
                <Image src={GitedeFrance} className="bi bi-cloud-check align-self-center " width="200" height="200" roundedCircle/>
                <Card.Body className="text-center">
                    <h2 className="text-center text-kease">Gîtes de France</h2>
                </Card.Body>
            </Card>
        </CardGroup>
        <br/>

        <Card className="border-0 mx-auto bg-kease" >
            <Card.Body className="text-center">
                <h2 className="text-center text-white">Les Hôtels Mythiques</h2>
            </Card.Body>
        </Card>
        <br/>

        <CardGroup className="card-deck">
            <Card className="border-0 mx-auto" >
                <Image src={Normandy} className="bi bi-cloud-check align-self-center " width="200" height="200" roundedCircle/>
                <Card.Body className="text-center">
                    <h2 className="text-center text-kease">Le Normandy</h2>
                </Card.Body>
            </Card>
            <Card className="border-0 mx-auto" >
                <Image src={Royal} className="bi bi-cloud-check align-self-center " width="200" height="200" roundedCircle/>
                <Card.Body className="text-center">
                    <h2 className="text-center text-kease">Le Royal</h2>
                </Card.Body>
            </Card>
        </CardGroup>
        <br/><br/>

    </>
  );
};

export default Hebergement