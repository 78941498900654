import '../../index.css';
import PublicMenu from './PublicMenu';
import PrivateMenu from './PrivateMenu';
import { useContext } from 'react';
import UserContext from '../utils/UserContext';


const MenuItems : React.FC = ()  => {
    const { user } = useContext(UserContext);

    if (!user) {
        // Redirigez vers la page de connexion si l'utilisateur n'est pas connecté
        return <PublicMenu/>;

    } else {
        // Redirigez vers une page non autorisée pour les autres rôles d'utilisateur
        return <PrivateMenu />;
    }

};

export default MenuItems


