import React, { useEffect, useRef } from 'react';
import ReactGeoloc from '../../assets/bus.svg';
import ReactHotel from '../../assets/recohotel.svg';

declare global {
  interface Window {
    google: any;
    initializeMap : any;
  }
}

interface GoogleMapProps {
  apiKey: string;
}

const GoogleMaps: React.FC<GoogleMapProps> = ({ apiKey }) => {
  const mapRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initializeMap`;
    script.async = true;
    script.defer = true;

    window.initializeMap = initializeMap; // Ajout d'une référence globale à la fonction initializeMap

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
      delete window.initializeMap; // Suppression de la référence globale à la fonction initializeMap lors du démontage
    };
  }, [apiKey]);

  const initializeMap = () => {
    if (mapRef.current) {
      // Options de la carte
      const mapOptions = {
        center: { lat: 49.3601178, lng: 0.0720211 }, // Coordonnées du centre de la carte (par exemple, Deauville)
        zoom: 15.8, // Niveau de zoom initial
        styles: [
          {
            featureType: 'all',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }], // Masquer les étiquettes de tous les types de fonctionnalités
          },
          {
            featureType: 'road',
            elementType: 'labels',
            stylers: [{ visibility: 'on' }], // Afficher les étiquettes des routes
          },
          // Autres styles personnalisés...
        ],
      };

      // Création de la carte
      const map = new window.google.maps.Map(mapRef.current, mapOptions);

      // Ajouter des marqueurs, des polygones, des polylignes, etc. à la carte selon vos besoins
      const locations = [
        { lat: 49.3592229, lng: 0.0719692, title: 'Mairie de Deauville', icon: ReactGeoloc },
        { lat: 49.3612427, lng: 0.0698105, title: 'Novotel', icon: ReactHotel },
        { lat: 49.3604363, lng: 0.0756756, title: 'Ibis', icon:  ReactHotel },
        { lat: 49.3609710, lng: 0.0771707, title: 'Mercure', icon:  ReactHotel },
        { lat: 49.3594979, lng: 0.0680205, title: 'Le Normandie', icon:  ReactHotel },
        { lat: 49.3587412, lng: 0.0647296, title: 'Le Royal', icon:  ReactHotel },
        // Ajoutez autant de marqueurs que vous le souhaitez
      ];
      
      // Exemple d'ajout d'un marqueur
      const addMarkersToMap = () => {
        locations.forEach((location) => {
          const marker = new window.google.maps.Marker({
            position: { lat: location.lat, lng: location.lng },
            map,
            title: location.title,
            icon: {
              url: location.icon,
              scaledSize: new window.google.maps.Size(26, 26),
              labelOrigin: new window.google.maps.Point(13, 32), // Position du label en fonction de l'icône
            },
            label: {
              text: location.title,
              color: 'black',
              fontWeight: 'bold',
              fontSize: '10px',
            },
          });
        });
      };

      addMarkersToMap();
    }
  };

  return <div style={{ width: '100%', height: '800px' }} id="map" className="mariagephoto" ref={mapRef} />;
};

export default GoogleMaps;
