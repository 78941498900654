import React from 'react';
import { Card, CardGroup, Container } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import ReactHebergement
 from '../../assets/Hotel.svg';
import ReactContact from '../../assets/contact.svg';
import ReactNavettes from '../../assets/navettes.svg';
import ReactCagnotte from '../../assets/cagnotte.svg';
import ReactPlanning from '../../assets/planning.svg';

const Features: React.FC = () => {

    const navigate = useNavigate();
    
    function Createlink(linky:any){
    navigate('/' + linky, { replace: true });
    }


  return (
    <>
        <Container fluid>
   
        <br/>
        <br/>
        <CardGroup className="card-deck">
            <Card className="features rounded-top-kease card border-0">
                <div className="text-center" onClick={(e:any) => {Createlink('programme')}}>
                <img src={ReactPlanning} className="bi bi-cloud-check align-self-center" width="200" height="200"/>
                <div className="card-body">
                    <h5 className="card-title text-center">Le Programme</h5>
                    <p className="card-text text-left">Découvrez le programme des festivités.</p>
                </div>
                </div>
            </Card>
            <Card className="features rounded-top-kease card border-0">
                <div className="text-center" onClick={(e:any) => {Createlink('hebergement')}}>
                <img src={ReactHebergement} className="bi bi-cloud-check align-self-center" width="200" height="200"/>
                <div className="card-body">
                    <h5 className="card-title text-center">Hébergement</h5>
                    <p className="card-text text-left">Regardez les lieux que nous vous recommandons.</p>
                </div>
                </div>
            </Card>
        </CardGroup>
        <br/>
        <br/>
        <CardGroup className="card-deck">
            <Card className="features rounded-bottom-kease card border-0">
                <div className="text-center" onClick={(e:any) => {Createlink('navettes')}}>
                <img src={ReactNavettes} className="bi bi-cloud-check align-self-center" width="200" height="200"/>
                <div className="card-body">
                    <h5 className="card-title text-center">Navettes</h5>
                    <p className="card-text text-left">Rentrez en toute sécurité.</p>
                </div>
                </div>
            </Card>
            
            <Card className="features rounded-bottom-kease card border-0">
                <div className="text-center" onClick={(e:any) => {Createlink('contacts')}}>
                <img src={ReactContact} className="bi bi-cloud-check align-self-center" width="200" height="200"/>
                <div className="card-body">
                    <h5 className="card-title text-center">Contacts</h5>
                    <p className="card-text text-left">Vous avez des questions appelez-nous.</p>
                </div>
                </div>
            </Card>

        </CardGroup>
        <br/>
        <br/>
        <CardGroup className="card-deck">
            <Card className="features rounded-bottom-kease card border-0">
                <div className="text-center" onClick={(e:any) => {Createlink('lune-de-miel')}}>
                <img src={ReactCagnotte} className="align-self-center" width="200" height="200"/>
                <div className="card-body">
                    <h5 className="card-title text-center">Lune de miel</h5>
                    <p className="card-text text-left">Participez à notre voyage de noces.</p>
                </div>
                </div>
            </Card>

        </CardGroup>
        </Container>
        <br/>
        <br/>
    </>
  );
};

export default Features