import { Navigate, useLocation } from 'react-router';

interface AuthProps {
  userProfile: any;
  children: JSX.Element;
}

const AdminAuth: React.FC<AuthProps> = ({ userProfile, children }) => {
  const location = useLocation();

  if (!userProfile) {
    // Redirigez vers la page de connexion si l'utilisateur n'est pas connecté
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (userProfile.statut === 'admin') {
    // Affichez le contenu de l'enfant si le rôle de l'utilisateur est "validated" ou "admin"
    return <>{children}</>;
  } else {
    // Redirigez vers une page non autorisée pour les autres rôles d'utilisateur
    return <Navigate to="/" state={{ from: location }} replace />;
  }
};

export default AdminAuth