import { Navigate, useLocation } from 'react-router';
import UserContext from '../components/utils/UserContext';
import { useContext } from 'react';

interface AuthProps {
  children: JSX.Element;
}

const RequiredAuth: React.FC<AuthProps> = ({ children })=> {
  const location = useLocation();
  const { user, userProfile } = useContext(UserContext);

  if (!user) {
    // Redirigez vers la page de connexion si l'utilisateur n'est pas connecté
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (userProfile?.statut === "en attente validation" || userProfile?.statut === "validated" || userProfile?.statut === "admin") {
    // Affichez le contenu de l'enfant si le rôle de l'utilisateur est "en attente validation", "validated" ou "admin"
    return children;
  } else {
    // Redirigez vers une page non autorisée pour les autres rôles d'utilisateur
    return <Navigate to="/" state={{ from: location }} replace />;
  }
};

export default RequiredAuth