import { Button, Card, CardGroup, Col, Row } from "react-bootstrap"
import { dbPhotos } from "../../utils/firebaseconfig";
import { useEffect, useState } from "react";
import { listAll, ref, getDownloadURL  } from 'firebase/storage';
import Image from 'react-bootstrap/Image'
import Photos from "../onepage/Photos";

const Download: React.FC = () => {
  
  const weddingDate = new Date("Jul 8, 2023 17:00:00").getTime();
   const now = new Date().getTime();
  const distance = weddingDate - now;

  const [items, setItems] = useState([]);

  useEffect(() => {
    const listRef = ref(dbPhotos, 'mariage');
    listAll(listRef)
      .then((res) => {
        const promises = res.items.map((itemRef) => {
          return getDownloadURL(itemRef).then((url) => {
            return {
              src: url,
            };
          });
        });
        Promise.all(promises).then((items) => {
          setItems(items);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    
    <>
    {distance < 0 && (
      <>
      <Card className="border-0 mx-auto" style={{ width: '30rem' }}>
        <Card.Body>
          <h2 className="text-center text-kease mb-2">Découvrez les photos du mariage</h2>
          <br/>
        </Card.Body>
      </Card>
      <Row xs={1} md={2} lg={3} xl={4} className="g-4 mx-auto">
        {items.map((item) => (
          <Card className="border-0 mx-auto" >
            <Image src={item.src} className="bi bi-cloud-check align-self-center " width="200" height="200" roundedCircle/>
          </Card>
        ))}
      </Row>
      <br/><br/>
      </>
      )}

      {distance > 0 && (
      <>
        <Photos/>
      </>
      )}
    </>
  )
}

export default Download;