import { Card, Col, Form, Row } from 'react-bootstrap';
import Pressoir from '../../assets/Pressoir-Tourgeville-2.jpg';
import Mairie from '../../assets/Mairie.jpeg';


const Navettes: React.FC = () => {

  return (
    <>

        <Card className="border-0 mx-auto" >
          <Card.Body>
            <h2 className="text-center mb-4 text-kease">Les Navettes</h2>
          </Card.Body>
        </Card>
          
        <Card className="border-0 mx-auto">
          <Row className="mx-auto">
          <Col md={6}>
            <Card.Img className="mariagephoto" src={Pressoir}/>
          </Col>
          <Col md={6}>
            <Card.Body>
              <br/>
              <h2 className="text-center mb-4 text-kease">Déposez votre véhicule au pressoir</h2>
              <h3 className="text-center mb-4 text-kease">Le parking est sécurisé et fermé</h3>
              <br/>
            </Card.Body>
          </Col>
          </Row>
        </Card>

        <Card className="border-0 mx-auto">
          <Row className="mx-auto">
          <Col md={6}>
            <Card.Body>
              <br/>
              <h2 className="text-center mb-4 text-kease">Rentrez en sécurité - Prenez une Navette</h2>
              <h3 className="text-center mb-4 text-kease">A partir de 23h00 on vous dépose à la Mairie de Deauville</h3>
              <br/>
              <p className="text-center mb-4">Un véhicule toutes les 30 min en fonction du précédent départ.</p>
            </Card.Body>
          </Col>
          <Col md={6}>
            <iframe width="800" height="800" className="mariagephoto" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d8680.28085484385!2d0.07371357050425795!3d49.35947677129163!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e1d4ad1b6bd273%3A0x16a9a2f05d418337!2sMairie%20de%20Deauville!5e0!3m2!1sfr!2sfr!4v1678458177979!5m2!1sfr!2sfr" />
          </Col>
          </Row>
        </Card>
        <Card className="border-0 mx-auto">
          <Row className="mx-auto">
          <Col md={6}>
            <Card.Img className="mariagephoto" src={Mairie}/>
          </Col>
          <Col md={6}>
            <Card.Body>
              <br/>
              <h2 className="text-center mb-4 text-kease">Mais comment je reviens?</h2>
              <h3 className="text-center mb-4 text-kease">Nous avons tout prévu</h3>
              <br/>
              <p className="text-center mb-4">Plusieurs Vans seront disponibles pour vous amener au brunch à partir de la Mairie de Deauville.</p>
              <p className="text-center mb-4">10h30 | 11h00 | 11h30</p>
              <br/>
            </Card.Body>
          </Col>
          </Row>
        </Card>

      <div className="w-100 text-center mt-2">
        
      </div>

    </>
  );
};

export default Navettes