import { Container, Navbar } from 'react-bootstrap';

import Features from './Features';
import Photo from './Photo';
import { useNavigate } from 'react-router-dom';



const Onepage: React.FC = () => {
  const navigate = useNavigate();
  
  return (
    <>
      <Container fluid>
        <Photo/>
      </Container>
      <Container fluid>
        <Features/>
      </Container>
    </>
  );
};

export default Onepage