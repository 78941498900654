import { Button, Card, CardGroup, Col, Container, Form, Row } from "react-bootstrap"
import { auth, dbCollection } from "../../../utils/firebaseconfig";
import {  ref,  onValue, orderByChild, off, update } from "firebase/database";
import { useEffect, useRef, useState } from "react";
import CircularProgressBar from "../../utils/CircularProgressBar";


const Admin: React.FC = () => {

  const [cards, setCards] = useState<Array<any>>([]);

  useEffect(() => {
    const usersRef = ref(dbCollection, 'users');
    
    onValue(usersRef, (snapshot) => {
        const cardsData: Array<any> = [];
        snapshot.forEach((childSnapshot) => {
            const user = childSnapshot.val();
            if (user.statut === 'en attente validation') {
                cardsData.push({
                    key: childSnapshot.key,
                    firstname: user.firstname,
                    lastname: user.lastname,
                    email: user.email,
                    userRole: user.statut,
                    phone: user.phone,
                    table:user.table,
                    nombre:user.nombre,
                });
            }   
      });
      setCards(cardsData);
    });
  }, []);

  async function ValidateUser(keyUser: string) {
    await update(ref(dbCollection, 'users/' + keyUser), {
      statut: "validated"
    });
  }

  const [validates, setValidates] = useState<Array<any>>([]);

  useEffect(() => {
    const usersRef = ref(dbCollection, 'users');
    
    onValue(usersRef, (snapshot) => {
        const ValidatesData: Array<any> = [];
        snapshot.forEach((childSnapshot) => {
            const user = childSnapshot.val();
            if (user.statut === 'validated') {
                ValidatesData.push({
                    key: childSnapshot.key,
                    firstname: user.firstname,
                    lastname: user.lastname,
                    email: user.email,
                    userRole: user.statut,
                    phone: user.phone,
                    table:user.table,
                    nombre:user.nombre,
                });
            }   
      });
      setValidates(ValidatesData);
    });
  }, []);

  async function UnValidateUser(keyUser: string) {
      await update(ref(dbCollection, 'users/' + keyUser), {
        statut: "en attente validation"
    });
  }

  const [cagnotte, setCagnotte] = useState();

  useEffect(() => {
    onValue(ref(dbCollection, 'progress/'), snapshot => {
      setCagnotte(snapshot.val());
    });
  }, []);

  const cagnotteRef = useRef<HTMLInputElement | null>(null);
  async function updateCagnotte(valueRef: React.MutableRefObject<HTMLInputElement>) {
      await update(ref(dbCollection), {
        progress : valueRef.current.value,
      });
  }


  return (
    <>
    <Card className="border-0 mx-auto">
        <Card.Body>
          <h2 className="text-center text-kease mb-2">Avancée de la cagnotte</h2>
          <p className="text-center small">(Ne pas mettre de % - pour 12% inscrire 12)</p>
        </Card.Body>
        <Row className="mx-auto">
            <Col className="align-self-center text-center">
              <CircularProgressBar progress={cagnotte}></CircularProgressBar>
            </Col>
            <Col className="align-self-center text-center">
            <Form.Group className="mb-3 ">
              <Form.Control type="string" ref={cagnotteRef} placeholder="Nouvelle valeur"/>
                <br/>
                <Button className="text-center btn-kease w-100" onClick={(e:any) => {updateCagnotte(cagnotteRef)}}>
                  Valider
                </Button>
              </Form.Group>
            </Col>
          </Row>
      </Card>
      
      <br/>
      {cards.length > 0 && (  
        <>
      <Card className="border-0 mx-auto">
        <Card.Body>
          <h2 className="text-center text-kease mb-2">Liste des invités en attente de validation</h2>
          <br/>
        </Card.Body>
      </Card>
      <Row xs={1} md={2} lg={3} xl={4} className="g-4 mx-auto">
        
            {cards.map((card) => (
              <CardGroup className="card-deck">
                <Card key={card.key} className="border-1 m-3 roudedcard mx-auto" >
                    <Card.Body>
                    <Card.Title className="">{card.firstname} {card.lastname}</Card.Title>
                    <Card.Text className="m-0">
                        email: {card.email}
                    </Card.Text>
                    <p>tel :<noindex><a href={`tel:${card.phone}`} className="text-kease" >{card.phone}</a></noindex></p>
                    </Card.Body>
                    <Card.Footer className="text-muted d-flex justify-content-between align-items-center">
                        <Button className="border-1 w-50" onClick={(e:any) => {ValidateUser(card.key)}}>Valider l'utilisateur</Button>
                    </Card.Footer>
                <br/>
                </Card>
              </CardGroup>
            )
        )}
        
      </Row>

      <br/>
      </>
      )}
      <Card className="border-0 mx-auto" >
        <Card.Body>
          <h2 className="text-center text-kease mb-2">Liste des invités validés</h2>
          <br/>
        </Card.Body>
      </Card>
      <Row xs={1} md={2} lg={3} xl={4} className="g-4 mx-auto">
            {validates.map((validate) => (
              <CardGroup className="card-deck">
                <Card key={validate.key} className="border-1 m-3 roudedcard mx-auto" >
                    <Card.Body>
                    <Card.Title className="">{validate.firstname} {validate.lastname}</Card.Title>
                    <Card.Text className="m-0">
                        email: {validate.email}
                    </Card.Text>
                    <p>tel :<noindex><a href={`tel:${validate.phone}`} className="text-kease" >{validate.phone}</a></noindex></p>
                    </Card.Body>
                    <Card.Footer className="border-0 bg-transparent text-muted d-flex justify-content-between align-items-center">
                        
                        <Button variant="danger" className="border-1 w-50 " onClick={(e:any) => {UnValidateUser(validate.key)}}>Enlever les droits</Button>
                    </Card.Footer>
                <br/>
                </Card>
                </CardGroup>
            )
        )}
        </Row>

    </>
  )
}

export default Admin;