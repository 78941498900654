import React, { useEffect, useState } from 'react';
import {  Card, CardGroup, Container } from 'react-bootstrap';
import Mariage from '../../assets/Mariage-white.svg';
import { dbPhotos } from '../../utils/firebaseconfig';
import Image from 'react-bootstrap/Image'
import { listAll, ref, getDownloadURL  } from 'firebase/storage';

const Contacts: React.FC = () => {
    const [ReactMariee, setMariee] = useState<string>('');
    const [ReactMarie, setMarie] = useState<string>('');
    const [ReactTemoin1, setTemoin1] = useState<string>('');
    const [ReactTemoin2, setTemoin2] = useState<string>('');
    const [ReactTemoin3, setTemoin3] = useState<string>('');
    const [ReactTemoin4, setTemoin4] = useState<string>('');
    const [ReactTemoin5, setTemoin5] = useState<string>('');
    const [ReactTemoin6, setTemoin6] = useState<string>('');

      const [photos, setPhotos] = useState<string[]>([
        'contacts/mariee.jpg',
        'contacts/marie.jpg',
        'contacts/temoin1.jpg',
        'contacts/temoin2.jpg',
        'contacts/temoin3.jpg',
        'contacts/temoin4.jpg',
        'contacts/temoin5.jpg',
        'contacts/temoin6.jpg'
      ]);
      
      useEffect(() => {
        photos.forEach((photo, index) => {
          const storageRef = ref(dbPhotos, photo);
          // Récupération de l'URL de téléchargement de l'image
          getDownloadURL(storageRef)
            .then((url) => {
              switch (index) {
                case 0:
                  setMariee(url);
                  break;
                case 1:
                  setMarie(url);
                  break;
                case 2:
                  setTemoin1(url);
                  break;
                case 3:
                  setTemoin2(url);
                  break;
                case 4:
                  setTemoin3(url);
                  break;
                case 5:
                  setTemoin4(url);
                  break;
                case 6:
                  setTemoin5(url);
                  break;
                case 7:
                  setTemoin6(url);
                  break;
                default:
                  break;
              }
            })
            .catch((error) => {
              console.error(error);
            });
        });
      }, []);

  return (
    <>

    <Container fluid>
        
        <Card className="border-0 mx-auto" >
          <Card.Body>
            <h2 className="text-center mb-4 text-kease">Les Contacts</h2>
          </Card.Body>
        </Card>
        <br/>
        <div className="border border-2 rounded border-kease">
        <Card className="border-0 mx-auto" >
            <Card.Body className="text-center">
                <h2 className="text-center text-kease">Les Mariés</h2>
                <small className="text-muted">(Ne contacter qu'en cas d'urgence le jour J)</small>
                
            </Card.Body>
        </Card>

        <CardGroup className="card-deck">
            <Card className="border-0 mx-auto" >
                <Image src={ReactMariee} className="align-self-center" width="200" height="200" roundedCircle/>
                <Card.Body className="text-center">
                    <h2 className="text-center text-kease">La Mariée</h2>
                    <small className="text-muted">(N'a pas de poche dans sa robe - appeller le marié)</small>
                    <p><noindex><a href="tel:+33667372796" className="stretched-link text-kease">06 67 37 27 96</a></noindex></p>
                </Card.Body>
            </Card>
            <Card className="border-0 mx-auto" >
            <Image src={ReactMarie} className="bi bi-cloud-check align-self-center img-fluid" width="200" height="200" roundedCircle/>
                <Card.Body className="text-center">
                    <h2 className="text-center text-kease">Le Marié</h2>
                    <small className="text-muted">(N'a jamais son tel - appeler la mariée)</small>
                    <p><noindex><a href="tel:+33 6 99 98 40 08" className="stretched-link text-kease">06 82 87 52 59</a></noindex></p>
                </Card.Body>
            </Card>
        </CardGroup>
        </div>
        <br/>
        <br/>

        <Card className="border-0 mx-auto bg-kease" >
            <Card.Body className="text-center">
                <h2 className="text-center text-white">Les Témoins</h2>
            </Card.Body>
        </Card>
        <br/>
        <CardGroup className="card-deck">
            <Card className="border-0 mx-auto" >
                <Image src={ReactTemoin1} className="bi bi-cloud-check align-self-center " width="200" height="200" roundedCircle/>
                <Card.Body className="text-center">
                    <h2 className="text-center text-kease">Roxane</h2>
                    <small className="text-muted">(Amie d'enfance de la Mariée)</small>
                    <p><noindex><a href="tel:+33678028636" className="stretched-link text-kease">06 78 02 86 36</a></noindex></p>
                </Card.Body>
            </Card>
            <Card className="border-0 mx-auto" >
                <Image src={ReactTemoin2} className="bi bi-cloud-check align-self-center " width="200" height="200" roundedCircle/>
                <Card.Body className="text-center">
                    <h2 className="text-center text-kease">Camille</h2>
                    <small className="text-muted">(Amie d'enfance de la Mariée et Organisatrice de la céremonie)</small>
                    <p><noindex><a href="tel:+33767919765" className="stretched-link text-kease">07 67 91 97 65</a></noindex></p>
                </Card.Body>
            </Card>
            <Card className="border-0 mx-auto" >
                <Image src={ReactTemoin3} className="bi bi-cloud-check align-self-center " width="200" height="200" roundedCircle/>
                <Card.Body className="text-center">
                    <h2 className="text-center text-kease">Maud</h2>
                    <small className="text-muted">(Amie de la Mariée )</small>
                    <p><noindex><a href="tel:+33699984008" className="stretched-link text-kease">06 99 98 40 08</a></noindex></p>
                </Card.Body>
            </Card>
        </CardGroup>
        
        <br/>
        <br/>

        <CardGroup className="card-deck">
            <Card className="border-0 mx-auto" >
                <Image src={ReactTemoin4} className="bi bi-cloud-check align-self-center " width="200" height="200" roundedCircle/>
                <Card.Body className="text-center">
                    <h2 className="text-center text-kease">Emilie</h2>
                    <small className="text-muted">(Amie d'enfance du Marié)</small>
                    <p><noindex><a href="tel:+41796210299" className="stretched-link text-kease">+41 79 621 02 99</a></noindex></p>
                </Card.Body>
            </Card>
            <Card className="border-0 mx-auto" >
            <Image src={ReactTemoin5} className="bi bi-cloud-check align-self-center " width="200" height="200" roundedCircle/>
                <Card.Body className="text-center">
                    <h2 className="text-center text-kease">Antoine</h2>
                    <small className="text-muted">(Petit frère de la Mariée et adopté par le Marié)</small>
                    <p><noindex><a href="tel:+33663873057" className="stretched-link text-kease">06 63 87 30 57</a></noindex></p>
                </Card.Body>
            </Card>
            <Card className="border-0 mx-auto" >
            <Image src={ReactTemoin6} className="bi bi-cloud-check align-self-center " width="200" height="200" roundedCircle/>
                <Card.Body className="text-center">
                    <h2 className="text-center text-kease">Lucas</h2>
                    <small className="text-muted">(Ami d'enfance de la Mariée et Organisateur de la cérémonie)</small>
                    <p><noindex><a href="tel:+33632225115" className="stretched-link text-kease">06 32 22 51 15</a></noindex></p>
                </Card.Body>
            </Card>
        </CardGroup>
        <br/>
        <br/>

      </Container>
    </>
  );
};

export default Contacts

