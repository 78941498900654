import { Nav, Navbar } from 'react-bootstrap';
import '../../index.css';
import {ReactComponent as ReactDeparture} from '../../assets/plane-departure-solid.svg';
import {ReactComponent as ReactMap} from '../../assets/map-marked-alt-solid.svg';
import {ReactComponent as ReactDirection} from '../../assets/map-signs-solid.svg';
import {ReactComponent as ReactAround} from '../../assets/street-view-solid.svg';
import {ReactComponent as ReactPhoto} from '../../assets/photo.svg';
import {ReactComponent as ReactContact} from '../../assets/call.svg';
import { useLinkNavigation } from './MenuFonctions';

const LinkPrivateMenu: React.FC<{ onExpandedChange: () => void }> = ({ onExpandedChange }) => {

    const { createLink } = useLinkNavigation();

    const handleClick = (useLink:any) => {
        createLink(useLink); // Utilisation de la fonction createLink
        onExpandedChange(); // Appel de la fonction de mise à jour de l'état expanded
      };


    return (
        <>
            <Navbar.Collapse>
                <Nav className="me-auto">
                    <Nav.Link onClick={() => handleClick("programme")} className="m-3" >
                        Le Programme{' '}
                        <ReactDirection className="bi bi-cloud-check align-self-center" width="25" height="25"/>
                    </Nav.Link>
                    <Nav.Link onClick={() => handleClick("navettes")} className="m-3">  
                        Transport{' '}
                        <ReactAround className="bi bi-cloud-check align-self-center" width="25" height="25"/>
                    </Nav.Link>
                    <Nav.Link onClick={() => handleClick("hebergement")} className="m-3">  
                        Hébergement{' '}
                        <ReactMap className="bi bi-cloud-check align-self-center" width="25" height="25"/>
                    </Nav.Link>
                    <Nav.Link onClick={() => handleClick("lune-de-miel")} className="m-3" >
                        Lune de miel{' '}
                        <ReactDeparture className="bi bi-cloud-check align-self-center" width="25" height="25"/>
                    </Nav.Link>
                    <Nav.Link onClick={() => handleClick("download")} className="m-3" >
                        Photos{' '}
                        <ReactPhoto className="bi bi-cloud-check align-self-center" width="25" height="25"/>
                    </Nav.Link>
                    <Nav.Link onClick={() => handleClick("contacts")} className="m-3" >
                        Contacts{' '}
                        <ReactContact className="bi bi-cloud-check align-self-center" width="25" height="25"/>
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>       
        </>
    );

};

export default LinkPrivateMenu


