import { useEffect, useState } from 'react';
import AppRouter from './approuter/AppRouter';
import { AppFooter } from './components/appfooter/AppFooter';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, dbCollection } from './utils/firebaseconfig';
import UserContext from './components/utils/UserContext';
import { onValue, ref } from 'firebase/database';
import { Container } from 'react-bootstrap';
import MenuItems from './components/appheader/MenuItems';


const App: React.FC = () => {

  const [user, setUser] = useState<any>(null);
  const [userProfile, setUserProfile] = useState<any>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // L'utilisateur est connecté, mettre à jour le contexte avec les informations de l'utilisateur
        const userProfileRef = ref(dbCollection, 'users/' + auth.currentUser.uid);
  
        onValue(userProfileRef, (snapshot) => {
            const userProfileData = snapshot.val();
            setUserProfile(userProfileData);
        });
        setUser(user);
      }
    });
    // Nettoyage de l'écouteur lors du démontage du composant
    return () => unsubscribe();
  }, []);
  
  return (
      <Container fluid>
        <UserContext.Provider value={{ user, userProfile, setUser, setUserProfile }}>
          <header className="App-header mx-auto">  
            <MenuItems/>
          </header>
          <AppRouter/>

          <AppFooter />
        </UserContext.Provider>
      </Container>
    
  );
}

export default App;
