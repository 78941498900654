import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../utils/firebaseconfig';
import '../../index.css';
import {ReactComponent as ReactLogo} from '../../assets/logo_mariage.svg';
import { useContext, useEffect, useState } from 'react';
import { DropDirection } from 'react-bootstrap/esm/DropdownContext';
import { useLinkNavigation } from './MenuFonctions';
import UserContext from '../utils/UserContext';
import LinkPrivateMenu from './LinkPrivateMenu';

const PrivateMenu: React.FC = () => {

    const { expanded, expandedMenu, toggleExpanded, toggleExpandedMenu, createLink} = useLinkNavigation();
    const { userProfile, setUser, setUserProfile } = useContext(UserContext)
    const handleClick = (useLink:any) => {
        createLink(useLink); // Utilisation de la fonction createLink
      };

    const navigate = useNavigate();

    const handleLogout = async () => {
      await auth.signOut();
      setUser(null);
      setUserProfile(null);
      navigate('/');
    };
    
    let direction: DropDirection;
    if (window.innerWidth < 1000) {
        direction = 'up';
    } else {
        direction = 'down';
    };

    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
          setIsSmallScreen(window.innerWidth < 1200);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);

    return (
        <>
            <Navbar expanded={expanded} onToggle={toggleExpanded} className="color-mariage" variant="dark" fixed="top" expand="xl">
                <Container fluid>
                    <Navbar.Brand className="app-header-logo"  onClick={() => handleClick("")}>
                        <ReactLogo className="bi bi-cloud-check" width="50" height="50"/>
                    </Navbar.Brand>
                    <Navbar.Toggle/>
                    
                    {expandedMenu && 
                        <LinkPrivateMenu onExpandedChange={toggleExpanded}/>
                    }

                    {!expandedMenu && !isSmallScreen &&
                        <LinkPrivateMenu onExpandedChange={toggleExpanded}/>
                    }

                    <Navbar.Collapse className="text-white justify-content-end">
                        <Nav className="justify-content-end">
                            <NavDropdown onClick={() => toggleExpandedMenu() } drop={direction} align="end" title={auth.currentUser.email}>
                                {userProfile?.statut ==='admin' && (
                                    <>
                                        <NavDropdown.Item onClick={() => handleClick("admin")}>Administrateur</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => handleClick("tables")}>Tables</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => handleClick("logements")}>Logements</NavDropdown.Item>
                                        <NavDropdown.Divider /> 
                                    </>
                                )}
                                <NavDropdown.Item onClick={() => handleClick("user-profile")}>Paramètres</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => handleClick("contact-us")}>Contact</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={() => handleClick("download")}>Accès aux photos</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => handleClick("upload")}>Ajouter des photos</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={handleLogout}>Se déconnecter</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );

};

export default PrivateMenu


