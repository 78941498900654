import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { useState } from 'react';
import { dbPhotos } from "../../utils/firebaseconfig";
import { Button, Card, Col, Form, Row } from 'react-bootstrap';

interface Props {
  onComplete?: (downloadURL: string) => void;
}

const Upload: React.FC<Props> = ({ onComplete }) => {
  
  function strRandom(o: { includeUpperCase: any; includeNumbers: any; length: any; startsWithLowerCase: any; }) {
    var a = 10,
        b = 'abcdefghijklmnopqrstuvwxyz',
        c = '',
        d = 0,
        e = ''+b;
    if (o) {
      if (o.startsWithLowerCase) {
        c = b[Math.floor(Math.random() * b.length)];
        d = 1;
      }
      if (o.length) {
        a = o.length;
      }
      if (o.includeUpperCase) {
        e += b.toUpperCase();
      }
      if (o.includeNumbers) {
        e += '1234567890';
      }
    }
    for (; d < a; d++) {
      c += e[Math.floor(Math.random() * e.length)];
    }
    return c;
  }

  const cancelUpload = async () => {
    (document.getElementById('formFileMultiple') as HTMLInputElement).value = '';
    setPreviewUrls([])
  }

  const [selectedFile, setSelectedFile] = useState<FileList | null>(null);
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      setSelectedFile(fileList);
      const urls = [];
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList.item(i)!;
        const url = URL.createObjectURL(file);
        urls.push(url);
      }
      setPreviewUrls(urls);
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      const filesArray: File[] = [];
      for (let i = 0; i < selectedFile.length; i++) {
        filesArray.push(selectedFile.item(i)!);
      }
      for (const file of filesArray) {
        const fileExtension = file.name.split('.').pop();
        const randomCode = strRandom({
          includeUpperCase: true,
          includeNumbers: true,
          length: 20,
          startsWithLowerCase: true
        });
        const fileName = `${randomCode}.${fileExtension}`;
        const storageRef = ref(dbPhotos, 'mariage/' + fileName);
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        alert('Votre/vos fichiers sont bien enregistrés');
      }
      (document.getElementById('formFileMultiple') as HTMLInputElement).value = '';
      setPreviewUrls([]);
    }
  };

  
  return (
    <>
    <Card className="border-0 mx-auto">
        <Card.Body>
          <h2 className="text-center text-kease mb-2">Importez vos photos du mariage</h2>
        </Card.Body>
    </Card>
    <Card className="border-0 mx-auto">
        <Card.Body className="mx-auto">
          <Form.Group controlId="formFileMultiple" className="mb-3 mx-auto">
            <Form.Label>Choissisez votre/vos photos ou vidéos</Form.Label>
            <Form.Control className="mx-auto" type="file" multiple accept="image/*" onChange={handleFileChange} />
            <br/>
            <Card.Footer className="border-0 bg-transparent d-flex justify-content-between">
              <Button variant="danger" onClick={cancelUpload}>Annuler</Button>{' '}
              <Button variant="primary" onClick={handleUpload}>Importer</Button>
            </Card.Footer>
          </Form.Group>
        </Card.Body>
    </Card>
    {previewUrls.length > 0 && (
        <>
          <Card className="border-0 mx-auto">
            <Card.Body>
              <h2 className="text-center text-kease mb-2">Les photos que vous aller importer sont les suivantes : </h2>
              <br/>
            </Card.Body>
          </Card>
          <Row className="mx-auto">
            {previewUrls.map((url) => (
              <Col className="mx-auto" xs={12} sm={6} md={4} key={url}> 
                <Card className="mb-3">
                  <Card.Img variant="top" src={url} />
                </Card>
              </Col>
            ))}
          </Row>
        </>
      )}
    <br/>
    </>
  );
};

export default Upload;
