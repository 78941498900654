import { Button, Card, Col, Form, Row, Image } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { dbCollection, dbPhotos } from '../../utils/firebaseconfig';
import { listAll, ref, getDownloadURL  } from 'firebase/storage';
import ProgressCircle from "../utils/CircularProgressBar";
import { ref as dbRef, onValue } from 'firebase/database';
import CircularProgressBar from '../utils/CircularProgressBar';

const Cagnotte: React.FC = () => {

    function Createlink(e: React.MouseEvent<HTMLButtonElement>){
        window.open("https://app.lyf.eu/pot/fr/pot/a9b3f389-24e3-41ae-8da0-2e2f4cde9f6f/informations", "_blank");
    }
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        setIsSmallScreen(window.innerWidth < 768);
      };
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    
    const [items, setItems] = useState([]);
    const [progress, setProgress] = useState();
    const [reactPanneau, setPanneau] = useState<string>('');

    

    useEffect(() => {
      const storageRef = ref(dbPhotos, 'noces/1-panneau.jpg')
      getDownloadURL(storageRef)
      .then((url) =>{
        setPanneau(url)
      })

      const progressRef = dbRef(dbCollection, 'progress/');
      onValue(progressRef, (snapshot) => {
        setProgress(snapshot.val());
      });

      const listRef = ref(dbPhotos, 'noces');
      listAll(listRef)
        .then((res) => {
          const promises = res.items.map((itemRef) => {
            return getDownloadURL(itemRef).then((url) => {
              return {
                src: url,
              };
            });
          });
          Promise.all(promises).then((items) => {
            setItems(items);
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }, []);

  return (
    <>

        <Card className="border-0 mx-auto" >
          <Card.Body>
            <h2 className="text-center mb-4 text-kease">Lune de miel</h2>
          </Card.Body>
        </Card>
          
        {!isSmallScreen && 
        <>
        <Card className="border-0 mx-auto w-50" >
          <Card.Body>
          <p>Nous avons acheté notre espace de vie à Boulogne-Billancourt, et nous l'avons aménagé - notre décoratrice d'intérieur (Charlotte) a fait un gros travail.</p>
          <p>Nous avons déjà tout l'équipement nécessaire pour la cuisine (et le cuisinier - Romain).</p>
          <p>Nous avons créé une cagnotte de mariage pour ceux qui souhaitent contribuer à notre voyage de noces direction Tahiti (idéalement).</p>
          <br/>
          <p>Nous mettrons aussi une enveloppe à disposition le jour du Mariage.</p>

          <p>Nous tenons tous à vous remercier.</p>
         
          </Card.Body>
        </Card>
        <br/>
        <Row xs={1} md={2} lg={3} xl={4} className="g-4 mx-auto">
          {items.map((item) => (
          <Card className="border-0 mx-auto" >
            <Image src={item.src} className="bi bi-cloud-check align-self-center " width="200" height="200" roundedCircle/>
          </Card>
          ))}
        </Row>
        <br/>
        </>
        }
        {isSmallScreen &&
        <>
        <Card className="border-0 mx-auto" >
          <Card.Body>
          <p>Nous avons acheté notre espace de vie à Boulogne-Billancourt, et nous l'avons aménagé - notre décoratrice d'intérieur (Charlotte) a fait un gros travail.</p>
          <p>Nous avons déjà tout l'équipement nécessaire pour la cuisine (et le cuisinier - Romain).</p>
          <p>Nous avons créé une cagnotte de mariage pour ceux qui souhaitent contribuer à notre voyage de noces direction Tahiti (idéalement).</p>
          <br/>
          <p>Nous mettrons aussi une enveloppe à disposition le jour du Mariage.</p>

          <p>Nous tenons tous à vous remercier.</p>
         
          </Card.Body>
        </Card>
        <Card className="border-0 mx-auto" >
          <Image src={reactPanneau} className="bi bi-cloud-check align-self-center " width="200" height="200" roundedCircle/>
        </Card>
        </>
        }
        
        <Card className="border-0 mx-auto w-50" >
          <Card.Body className="text-center">
          <Button onClick={Createlink} className="border-0 btn btn-kease m-3" >
            Lien vers la cagnotte
            </Button>
          </Card.Body>
        </Card>
        <br/>
        <Card className="border-0 mx-auto" >
          <Card.Body className="text-center">
            Petit suivi de la cagnotte :
            
          </Card.Body>
            <Col className="align-middle mx-auto">
              <CircularProgressBar progress={progress}>

              </CircularProgressBar>
            </Col>
        </Card>
        <br/>
    </>
  );
};

export default Cagnotte