import React, { useEffect, useState } from 'react';
import {  Card, Container, Carousel } from 'react-bootstrap';
import Mariage from '../../assets/Mariage-white.svg';
import { dbPhotos } from '../../utils/firebaseconfig';
import { listAll, ref, getDownloadURL  } from 'firebase/storage';

const Photos: React.FC = () => {

  const [items, setItems] = useState([]);

  useEffect(() => {
    const listRef = ref(dbPhotos, 'maries');
    listAll(listRef)
      .then((res) => {
        const promises = res.items.map((itemRef) => {
          return getDownloadURL(itemRef).then((url) => {
            return {
              src: url,
            };
          });
        });
        Promise.all(promises).then((items) => {
          setItems(items);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
        <Card className="border-0 mx-auto" >
          <Card.Body>
            <h2 className="text-center mb-4 text-kease">Les photos</h2>
          </Card.Body>
        </Card>
          

        <Card className="w-75 mx-auto"  >
          <Card.Header>
            <h2 className="text-center">Les photos seront bien ici</h2>
          </Card.Header>
          <br/>
          <Card.Title>
              
          </Card.Title>
          <Card.Body className="text-center">
            <p>Il faudra attendre le mariage pour les obtenir.</p>
            <p>Pour des raisons de confidentialité, nous demanderons aux personnes de s'inscrire.</p><br/>
          </Card.Body>
          <Card.Footer className="text-muted text-center">Nous vous tiendrons informé de la publication.<br/>
          En attendant, découvrez quelques photos des mariés ci-dessous.</Card.Footer>
        </Card>
        <br/>
        <br/>
        <Carousel className="w-75 carousel-fade mx-auto d-block">
          {items.map((item) => (
            <Carousel.Item key={item.src} interval={3000}>
              <img className="img-fluid rounded mx-auto d-block" src={item.src} width="500" height="500"/>
            </Carousel.Item>
          ))}
        </Carousel>
        <br/>
        <br/>
    </>
  );
};

export default Photos