import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { dbCollection } from "../../../utils/firebaseconfig";
import { onValue, push, ref, remove, set } from 'firebase/database';
import { Row, Form, Button, Col, CardGroup } from 'react-bootstrap';

interface User {
  key: string;
  firstname: string;
  lastname: string;
  email: string;
  userRole: string;
  phone: string;
  table: string;
  nombre: number;
}

interface Table {
  name: string;
  users: User[];
  totalNombre: number;
}

const Tables: React.FC = () => {
  const [tables, setTables] = useState<Table[]>([]);
  const [newTableName, setNewTableName] = useState('');
  const [totalParticipant, settotalParticipant] = useState(0);
  const [newParticipant, setNewParticipant] = useState<any>({
    firstname: '',
    lastname: '',
    nombre: 1,
    statut: 'temporaire',
  });
  const [usersWithoutTable, setUsersWithoutTable] = useState<User[]>([]);

  useEffect(() => {
    // Récupérer tous les utilisateurs
    const usersRef = ref(dbCollection, 'users');
    onValue(usersRef, (snapshot) => {
      const usersData = snapshot.val();
      const tablesMap: { [tableName: string]: User[] } = {};
      const usersWithoutTable: User[] = [];

      if (usersData) {
        Object.keys(usersData).forEach((userKey) => {
          const user = usersData[userKey];
          const table = user.table;

          if (!tablesMap[table]) {
            tablesMap[table] = [];
          }

          tablesMap[table].push({
            key: userKey,
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            userRole: user.statut,
            phone: user.phone,
            table: user.table,
            nombre: user.nombre || 1, // Valeur par défaut de 1 si nombre est vide
          });

          if (!table) {
            usersWithoutTable.push({
              key: userKey,
              firstname: user.firstname,
              lastname: user.lastname,
              email: user.email,
              userRole: user.statut,
              phone: user.phone,
              table: '',
              nombre: user.nombre || 1, // Valeur par défaut de 1 si nombre est vide
            });
          }
        });
      }

      const tablesArray: Table[] = Object.keys(tablesMap).map((tableName) => {
        const users = tablesMap[tableName];
        const totalNombre = users.reduce((total, user) => total + user.nombre, 0);
        return {
          name: tableName,
          users: users,
          totalNombre: totalNombre,
        };
      });

      setTables(tablesArray);
      setUsersWithoutTable(usersWithoutTable);

    });
  }, []);

  useEffect(() => {
    const filteredTables = tables.filter((table) => table.name !== 'undefined');
    const total = filteredTables.reduce((sum, table) => sum + table.totalNombre, 0);
    settotalParticipant(total);
  }, [tables]);

  const handleUserDrag = (event: React.DragEvent<HTMLDivElement>, user: User) => {
    event.dataTransfer.setData('text/plain', user.key);
  };

  const handleTableDrop = (event: React.DragEvent<HTMLDivElement>, table: string) => {
    event.preventDefault();
    const userKey = event.dataTransfer.getData('text/plain');

    // Mettre à jour la valeur de la table de l'utilisateur
    set(ref(dbCollection, `users/${userKey}/table`), table);
  };

  const handleTableDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleNombreClick = (event: React.MouseEvent<HTMLParagraphElement>, user: User) => {
    const newNombreString = window.prompt('Nouvelle valeur pour nombre', user.nombre.toString());
    const newNombre = parseInt(newNombreString || '', 10) || 1; // Valeur par défaut de 1 si la saisie est vide ou non numérique

    // Mettre à jour la valeur de nombre de l'utilisateur
    set(ref(dbCollection, `users/${user.key}/nombre`), newNombre);
  };

  const handleNewTableSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (newTableName.trim() !== '') {
      setTables((prevTables) => {
        const newTable: Table = {
          name: newTableName,
          users: [],
          totalNombre: 0,
        };

        return [...prevTables, newTable];
      });

      setNewTableName('');
    }
  };

  const handleEditTableClick = (event: React.MouseEvent<HTMLHeadingElement>, tableName: string) => {
    const newTableName = window.prompt('Nouveau nom de la table', tableName);
  
    if (newTableName) {
      const usersRef = ref(dbCollection, 'users');
      onValue(usersRef, (snapshot) => {
        const usersData = snapshot.val();
  
        if (usersData) {
          Object.keys(usersData).forEach((userKey) => {
            const user = usersData[userKey];
  
            if (user.table === tableName) {
              const userRef = ref(dbCollection, `users/${userKey}/table`);
              set(userRef, newTableName);
            }
          });
        }
      });
    }
  };

  const handleNewParticipantSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (
      newParticipant.firstname.trim() !== '' &&
      newParticipant.lastname.trim() !== ''
    ) {
      const usersRef = ref(dbCollection, 'users');
      const newUserRef = push(usersRef);
      set(newUserRef, newParticipant);
    }
  };

  const handleDeleteUser = (userId: string) => {
    const userRef = ref(dbCollection, `users/${userId}`);
    remove(userRef)
      .then(() => {
        // Suppression réussie, effectuez les actions supplémentaires si nécessaire
      })
  };

  const handleDeleteUserTable = (userId: string) => {
    // Mettre à jour la valeur de la table de l'utilisateur
    set(ref(dbCollection, `users/${userId}/table`), '');
  };

  return (
    <>
      <Row xs={1} md={2} lg={3} xl={3} className="g-4 mx-auto">
        <Card className="border-0 mx-auto">
          <Card.Body>
            <h2 className="text-center text-kease">Ajouter une nouvelle table</h2>
          </Card.Body>
          <Row className="mx-auto">
            <Col className="align-self-center text-center">
              <Form onSubmit={handleNewTableSubmit}>
                <Form.Group className="d-flex align-items-center">
                  <Form.Label className="m-3 text-nowrap">Nom de la table</Form.Label>
                  <Form.Control className="m-3" type="string" value={newTableName} onChange={(event) => setNewTableName(event.target.value)} />
                  <Button type="submit" className="text-center btn-kease w-100 m-3" >Valider</Button>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Card>
        <Card className="border-0 mx-auto">
          <Card.Body>
            <h2 className="text-center text-kease">Ajouter un participant</h2>
          </Card.Body>
          <Row className="mx-auto">
            <Col className="align-self-center text-center">
              <Form onSubmit={handleNewParticipantSubmit}>
                <Form.Group className="d-flex align-items-center">
                  <Form.Label className="m-3">Prénom</Form.Label>
                  <Form.Control className="m-3" type="text" value={newParticipant.firstname} onChange={(event) => setNewParticipant({ ...newParticipant, firstname: event.target.value })} />
                  <Form.Label className="m-3">Nom</Form.Label>
                  <Form.Control className="m-3" type="text" value={newParticipant.lastname} onChange={(event) => setNewParticipant({ ...newParticipant, lastname: event.target.value })} />
                  <Button type="submit" className="text-center btn-kease w-100 m-3" >Valider</Button>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Card>
      </Row>
      <br />

      <Card className="border-0 mx-auto">
        <Card.Body>
          <h3 className="text-kease"> Nombre total de personnes placées : {totalParticipant}</h3>
        </Card.Body>
      </Card>

      {usersWithoutTable.length > 0  && (
        <>
          <Card className="border-0">
            <Card.Body>
              <h2 className="text-kease">Personnes non placées</h2>
            </Card.Body>
          </Card>

          <Row xs={2} md={4} lg={6} xl={8} className="g-4 mx-auto">
            {usersWithoutTable.map((user) => (
              <>
                <CardGroup className="card-deck m-0">
                  <Card className="border" key={user.key} draggable onDragStart={(event: React.DragEvent<HTMLDivElement>) => handleUserDrag(event, user)}>
                    <Card.Body className="p-1 d-flex justify-content-between align-items-center">
                      <p className={`m-0 ${user.userRole === 'temporaire' ? 'text-primary' : ''} ${user.userRole === 'validated' ? 'text-kease' : ''}`}>{user.firstname} {user.lastname}</p>
                      <svg onClick={() => handleDeleteUser(user.key)} style={{ marginLeft: '10px' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-x align-self-center" viewBox="0 0 16 16">
                        <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z"/>
                        <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm-.646-4.854.646.647.646-.647a.5.5 0 0 1 .708.708l-.647.646.647.646a.5.5 0 0 1-.708.708l-.646-.647-.646.647a.5.5 0 0 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 .708-.708Z"/>
                      </svg>
                    </Card.Body>
                  </Card>
                </CardGroup>
              </>
            ))}
          </Row>
        </>
      )}

      <br/>
      <div className="mx-auto">
        <Row xs={1} md={2} lg={3} xl={4} className="g-4 mx-auto">
          {tables.map((table) => (
            <>
            <CardGroup className="card-deck">
              {table.name && table.name !== 'undefined' && (
                <Card
                  key={table.name}
                  onDrop={(event: React.DragEvent<HTMLDivElement>) => handleTableDrop(event, table.name)}
                  onDragOver={handleTableDragOver}
                  className="border border-1 rounded border-kease"
                >
                  <Card.Header className="bg-kease" >
                    <h3 className="bg-kease text-center text-white" onClick={(event) => handleEditTableClick(event, table.name)}>{table.name}</h3>
                  </Card.Header>
                  <Card.Body>
                    {table.users.map((user) => (
                      <div
                        key={user.key}
                        draggable
                        onDragStart={(event: React.DragEvent<HTMLDivElement>) => handleUserDrag(event, user)}
                        className="d-flex justify-content-between align-items-center"
                      >
                        <div className="d-flex align-items-center">
                        <svg onClick={() => handleDeleteUserTable(user.key)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-dash mx-3" viewBox="0 0 16 16">
                          <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM11 12h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1 0-1Zm0-7a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"/>
                          <path d="M8.256 14a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z"/>
                        </svg>
                        <p className={`m-0 text-left ${user.userRole === 'temporaire' ? 'text-primary' : ''} ${user.userRole === 'validated' ? 'text-kease' : ''}`}>{user.firstname} {user.lastname}</p>
                        </div>
                        <p className="m-0"
                          onClick={(event) => handleNombreClick(event, user)}
                          style={{ cursor: 'pointer' }}
                        >
                          {user.nombre}
                        </p>
                      </div>
                    ))}
                  </Card.Body>
                  <Card.Footer className="bg-kease text-center text-white d-flex justify-content-between align-items-center">
                    <p>Total:</p>
                    <p>{table.totalNombre}</p>
                  </Card.Footer>
                </Card>
              )}
              </CardGroup>
            </>
          ))}
        </Row>
      </div>
      <br />

    </>
  );
};

export default Tables;
