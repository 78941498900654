import React, { useContext, useEffect, useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, dbCollection } from "../../utils/firebaseconfig";
import { getProfile } from "../utils/Functions";
import UserContext from "../utils/UserContext";
import { onValue, ref } from "firebase/database";


const Login: React.FC = () => {

    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const [error, setError] = useState("")
    const navigate = useNavigate()
    const { setUserProfile } = useContext(UserContext);
    
    async function signIn() {
        try {
          setError("");
          const email = emailRef.current!.value;
          const password = passwordRef.current!.value;
          // Authentification avec l'adresse e-mail et le mot de passe
              signInWithEmailAndPassword(auth, email, password)
              .then(() => {
                const userProfileRef = ref(dbCollection, 'users/' + auth.currentUser.uid);
  
                onValue(userProfileRef, (snapshot) => {
                    const userProfileData = snapshot.val();
                    setUserProfile(userProfileData);
                });
                
              });
        } catch {
          setError("Impossible de vous connecter");
        }
    }
      
    async function handleSubmit(e: any) {
        e.preventDefault();
        await signIn();
        navigate('/')
    }

    return (
        <>
            <Card className="border-0 mx-auto">
                <Card.Body className="mx-auto">
                <h2 className="text-center mb-4 text-kease">Connexion</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form className="mx-auto" onSubmit={handleSubmit}>
                    <Form.Group className="mb-3 mx-auto" id="email">
                        <Form.Control className="mx-auto" type="email" ref={emailRef} placeholder="email" required />
                    </Form.Group>
                    <Form.Group className="mb-3 mx-auto" id="password">
                        <Form.Control className="mx-auto" type="password" ref={passwordRef} placeholder="Mot de passe" required />
                    </Form.Group>
                    <Button className="mx-auto w-100 btn-kease" type="submit">
                    Se connecter
                    </Button>
                </Form>
                <br/>
                <div className="mx-auto text-center mb-3">
                    <Link to="/forgot-password">Oublie de votre mot de passe?</Link>
                </div>
                </Card.Body>
            </Card>
            <Card className="border-0 mx-auto">
                <Card.Text className="mx-auto">
                    Vous n'avez pas encore de compte ? <Link to="/create-account">S'inscrire</Link>
                </Card.Text>
            </Card >
            <br/>
        </>
    )
}

export default Login